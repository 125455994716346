
import {defineComponent, reactive, watch, watchEffect, computed, ref, Ref} from 'vue';
import {usePromise} from "@/components/usePromise";
import {BaseForm} from "@/types/form";
import {FieldType, ListField, NumberField, SelectField, TextField} from "@/types/field";
import {FieldOption} from "@/types/option";
import {
  BetweenValidationRule, MaxLengthValidationRule,
  MinLengthValidationRule,
  RequiredValidationRule,
  UrlValidationRule
} from "@/types/validation";
import {Logic, LogicMatch, LogicMethod, LogicRule, LogicRuleOperator} from "@/types/logic";
import {getErrorMessage, useAxios} from "@/plugins/axios";
import {SocialActionField} from "@/interfaces/campaign";
import FaqLink from "@/pages/faq/FaqLink.vue";

export default defineComponent({
  name: "AddSocialAction",
  components: {
    FaqLink
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    action: {
      type: Object,
      default: () => {
      }
    },
    actions: {
      type: Object,
      default: () => {
      }
    },
    campaignId: {
      type: String,
      default: '',
    }
  },
  emits: ['created', 'update:visible'],
  setup(props, {emit}) {

    //  Axios plugin
    const axios = useAxios();

    const form = reactive(new BaseForm());

    let values: { [key: string]: any } = reactive({
      social: '',
      action: '',
      points: 1,
    });

    const faqs: Ref<Array<{ id: number, title: string }>> = ref([]);

    watchEffect(() => {
      const platforms: Array<string> = Object.keys(props.actions ?? {});
      if (platforms.indexOf(values.social) > -1) {
        faqs.value = [];
        let currentAction = props.actions[values.social].filter((action: { key: string }) => action.key === values.action);
        if (currentAction.length === 1) {
          currentAction = currentAction[0];
          Object.keys(currentAction.faq).forEach((faqId: string) => {
            faqs.value.push({id: parseInt(faqId), title: currentAction.faq[faqId]});
          });
        }
      }
    });

    watch(() => props.actions, (actions) => {

      //  Remove entire form
      form.removeFields();

      //  Social platform field
      const platform = reactive(new SelectField('social', 'social.platform')
          .setWidth(6)
          .addRule(new RequiredValidationRule()));
      form.addField(platform);

      platform.removeOptions();
      Object.keys(actions ?? {}).forEach((action, index) => {

        //  Add social platform as option
        platform.addOption(new FieldOption(action, `social.${action}`));

        //  Create an actions field per social platform
        const socialActionField = reactive(new SelectField('action', `social.action`)
            .addRule(new RequiredValidationRule())
            .setLogic(new Logic().setMatch(LogicMatch.All).setOperator(LogicMethod.Show).addRule(new LogicRule('social', LogicRuleOperator.Is, action)))
            .setWidth(6));
        form.addField(socialActionField);
        actions[action].forEach((socialAction: any, socialActionIndex: number) => {
          socialActionField.addOption(new FieldOption(socialAction.key, socialAction.caption).preventTranslate());

          //  Action custom social actions fields
          (socialAction.fields ?? []).forEach((customActionField: SocialActionField) => {
            //  Input field
            if (customActionField.type === 'string') {
              values[customActionField.name] = '';
              const customActionInputField = new TextField(customActionField.name, customActionField.caption).preventTranslate()
                  .setLogic(reactive(new Logic()
                      .setMatch(LogicMatch.All)
                      .setOperator(LogicMethod.Show)
                      .addRule(new LogicRule('social', LogicRuleOperator.Is, action))
                      .addRule(new LogicRule('action', LogicRuleOperator.Is, socialAction.key)))
                  )
              customActionField.rules.forEach((rule: string) => {
                if (rule === 'required') {
                  customActionInputField.addRule(new RequiredValidationRule());
                }
                if (rule === 'url') {
                  customActionInputField.addRule(new UrlValidationRule());
                }
              })
              form.addField(customActionInputField);
              //  Array
            } else if (customActionField.type === 'array') {
              values[customActionField.name] = [];
              const customActionInputField = new ListField(customActionField.name, customActionField.caption)
                  .preventTranslate()
                  .showNumbers(true)
                  .setLogic(reactive(new Logic()
                      .setMatch(LogicMatch.All)
                      .setOperator(LogicMethod.Show)
                      .addRule(new LogicRule('social', LogicRuleOperator.Is, action))
                      .addRule(new LogicRule('action', LogicRuleOperator.Is, socialAction.key)))
                  )
              customActionField.rules.forEach((rule: string) => {
                if (rule === 'required') {
                  customActionInputField.addRule(new RequiredValidationRule());
                }
                if (rule === 'url') {
                  customActionInputField.addRule(new UrlValidationRule());
                }
                const lengthValidationRule = rule.split(':');
                if (lengthValidationRule.length === 2) {
                  switch (lengthValidationRule[0]) {
                    case 'min' :
                      customActionInputField.addRule(
                          new MinLengthValidationRule(parseInt(lengthValidationRule[1]))
                      );
                      break;
                    case 'max' :
                      customActionInputField.addRule(
                          new MaxLengthValidationRule(parseInt(lengthValidationRule[1]))
                      );
                      break;
                  }
                }
              })
              form.addField(customActionInputField);
            }
          });
          //  Set default value
          if (index === 0 && socialActionIndex === 0) {
            values.action = socialAction.key;
          }
        });

        //  Set default value
        if (index === 0) {
          values.social = action;
        }
      });

      form.addField(new NumberField('points', 'social.points')
          .setDefault(1)
          .addRule(new RequiredValidationRule())
          .addRule(new BetweenValidationRule(1, 10)));
    }, {immediate: true, deep: true})

    const createActionDrawer = reactive(new usePromise());
    const createAction = async () => {

      //  Open drawer
      const confirmed = await createActionDrawer.show();
      if (confirmed) {
        form.setError('').submit().then(() => {
          const body = {
            platform: values.social,
            action: values.action,
            fields: {},
            points: parseInt(values.points.toString()),
          }
          Object.keys(values).forEach(key => {
            if (['social', 'action'].indexOf(key) === -1) {
              // @ts-ignore
              body.fields[key] = values[key];
            }
          });


          if (isEditing.value) {
            axios.put(`campaign-action/${props.action.id}`, body).then(() => {
              createActionDrawer.saving = false;
              createActionDrawer.hide();
              emit('created');
              emit('update:visible', false);
            }).catch(error => {
              form.setError(getErrorMessage(error.response.data));
              createActionDrawer.saving = false;
              createAction();
            });
          } else {
            axios.post(`campaign/${props.campaignId}/action`, body).then(() => {
              createActionDrawer.saving = false;
              createActionDrawer.hide();
              emit('created');
              emit('update:visible', false);
            }).catch(error => {
              form.setError(getErrorMessage(error.response.data));
              createActionDrawer.saving = false;
              createAction();
            });
          }
        }).catch(() => {
          createActionDrawer.saving = false;
          createAction();
        })
      } else {
        createActionDrawer.saving = false;
        emit('update:visible', false);
      }
    }

    watch(() => props.visible, (visible) => {
      if (visible) {

        //  Set submitted to false
        form.submitted = false;

        //  Fill form
        if (Object.keys(props.action).length > 0) {
          values.social = props.action.platform;
          values.action = props.action.action;
          values.points = props.action.points;
          Object.keys(props.action.fields).forEach(key => {
            // @ts-ignore
            values[key] = props.action.fields[key];
          })
        } else {
          //  Reset all form values
          form.fields.forEach(field => {
            if ((field.type === FieldType.Select || field.type === FieldType.Radio) && field.options.length > 0) {
              values[field.model] = field.options[0].value;
            } else if (field.type === FieldType.Number) {
              values[field.model] = field.defaultValue;
            } else if (field.type === FieldType.List) {
              values[field.model] = [];
            } else {
              values[field.model] = '';
            }
          });
        }

        //  Show drawer
        createAction();
      }
    }, {deep: true, immediate: true})

    const isEditing = computed(() => {
      return Object.keys(props.action).length > 0
    });

    return {
      isEditing,
      createAction,
      createActionDrawer,
      form,
      values,
      faqs
    }
  }
})
