export interface ITooltip {
    text: string
}

export class Tooltip implements ITooltip {
    text: string = '';

    constructor(tooltip: string) {
        this.text = tooltip;
    }
}