<template>
    <span class="text-sm text-gray-900 pr-2"><i class="far fa-chevron-right"></i></span>
    <a href="#"
       class="text-sm text-gray-500 border-gray-500 border-b hover:border-transparent"
       @click.prevent="$faq.article(id, raiseTicket)">{{caption}}</a>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "FaqLink",
        props: {
            id: {
                type: [Number, String],
                default: '',
            },
            caption: {
                type: String,
                default: ''
            },
            raiseTicket: {
                type: Boolean,
                default: false
            }
        },
        setup() {
        }
    })
</script>