<template>
  <div class="pb-10">
    <div class="bg-custom-100 pt-10 pb-24">
      <div class="max-w-7xl mx-auto px-6 lg:px-8">
        <Header
            :name="router.currentRoute.value.params.id ? (campaign.loading || details.values.title === '' ? $translate('campaign.edit') : details.values.title) : $translate('campaign.create')"
            :previous="$translate('campaign.overview.title')"
            @on-previous="router.push({name:'dashboard'})"/>
      </div>
    </div>
    <div class="py-10 -mt-24">
      <Steps @change="onChangedStep" :loading="campaign.loading">
        <template #before/>
        <template #after/>
        <Step :name="$translate('company.title')" :valid="saveCompany"
              :loading="campaign.loading"
              v-if="!companyComplete">
          <CardHeader :name="$translate('company.title')"
                      :description="$translate('company.description')">
            <CompanyProfileForm class="pt-6" :save="bindSaveMethodCompany"/>
          </CardHeader>
        </Step>
        <Step :name="$translate('social.title')" :valid="saveSocial" :loading="campaign.loading"
              v-if="!socialComplete">
          <CardHeader :name="$translate('social.title')" :description="$translate('social.description')">
            <SocialProfilesForm class="pt-6" :save="bindSaveMethodSocial"/>
          </CardHeader>
        </Step>
        <Step :name="$translate('campaign.details.step')" :valid="details.valid" :loading="campaign.loading"
              saving="campaign.details.saving">
          <CardHeader :name="$translate('campaign.details.title')"
                      :description="$translate('campaign.details.description')">
            <template #actions>
                            <span class="pt-0.5">
                                <FaqLink :id="123" caption="Need help with this step?"/>
                            </span>
            </template>
            <DataForm class="pt-6" :form="details.form" v-model="details.values"/>
          </CardHeader>
        </Step>
        <Step :name="$translate('campaign.schedule.step')" :valid="schedule.valid" :loading="campaign.loading">
          <CardHeader :name="$translate('campaign.schedule.title')"
                      :description="$translate('campaign.schedule.description')">
            <DataForm class="pt-6" :form="schedule.form" v-model="schedule.values"/>
          </CardHeader>
        </Step>
        <Step :name="$translate('campaign.products.step')" :valid="products.valid" :loading="campaign.loading">

          <CardHeader :name="$translate('campaign.products.title')"
                      :description="$translate('campaign.products.description')" v-if="currentProduct.id">
            <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-12 sm:gap-x-8 pt-6">
              <div class="sm:col-span-12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mb-2">
                  {{ $translate('campaign.products.current') }}
                </label>
                <div class="mt-3  grid grid-cols-1 gap-5 sm:gap-6">
                  <div
                      class="flex-1 flex items-center justify-between border border-gray-200 bg-white rounded-md truncate pr-2">
                    <div class="flex-1 px-4 py-2 text-sm truncate">
                      <a href="#" class="text-gray-900 font-medium hover:text-gray-600">
                        {{ currentProduct.name }}
                      </a>
                      <p class="text-gray-500">{{ currentProduct.sku }}</p>
                    </div>
                    <div class="flex-shrink-0 pr-2">
                      <a href="#" @click.prevent="currentProduct.id = null;"
                         class="text-custom-600 text-sm hover:text-custom-900">
                        {{ $translate('campaign.products.change') }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardHeader>
          <CardHeader :name="$translate('campaign.products.title')"
                      :description="$translate('campaign.products.description')" v-if="!currentProduct.id">

            <DataForm class="pt-6" :form="products.form" v-model="products.values"/>
            <Loading class="pt-6" :loading="productSelection.loading"/>
            <div v-if="products.values.search !== '' && !productSelection.loading && productSelection.list.length === 0"
                 class="pt-6">
              <Empty :description="$translate('campaign.products.empty')"/>
            </div>
            <div v-if="products.values.search !== '' && !productSelection.loading && productSelection.list.length > 0"
                 class="pt-6">
              <label class="block text-sm font-medium leading-5 text-gray-700 mb-2">
                {{ $translate('campaign.products.choose') }}
              </label>
              <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                <div
                    :class="{'relative flex items-center px-6 py-5 space-x-3 col-span-1 bg-white rounded-lg border-2 py-4':true, 'border-gray-100 hover:border-gray-300': productSelection.id !== product.id, 'border-custom-500': productSelection.id === product.id}"
                    v-for="(product, index) in productSelection.list" :key="`product_${index}`"
                    @click.prevent="productSelection.id = product.id; productSelection.sku = product.sku;">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-md" :src="product.image" alt="">
                  </div>
                  <div class="flex-1 min-w-0">
                    <a href="#" class="focus:outline-none">
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-gray-900 truncate">
                        {{ product.name }}
                      </p>
                      <p class="text-sm text-gray-500 truncate">
                        {{ product.sku }}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </CardHeader>
        </Step>
        <Step :name="$translate('campaign.pitch.step')" :valid="pitch.valid" :loading="campaign.loading"
              saving="campaign.pitch.saving"
              v-if="details.values.reason !== 'brand-audience'">
          <CardHeader :name="$translate('campaign.pitch.title')"
                      :description="$translate('campaign.pitch.description')">
            <DataForm class="pt-6" :form="pitch.form" v-model="pitch.values"/>
          </CardHeader>
        </Step>
        <Step :name="$translate('social.details.step')" :valid="actions.valid" :loading="campaign.loading">
          <CardHeader :name="$translate('campaign.actions.title')"
                      :description="$translate('campaign.actions.description')">
            <template #actions>
              <button type="button" @click.prevent="actions.edit = {}; actions.visible = true"
                      v-if="actions.data.length > 0"
                      class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                {{ $translate('social.create.button') }}
              </button>
            </template>
          </CardHeader>
          <SocialActionView v-model:visible="actions.visible" :action="actions.edit"
                            :actions="actions.available"
                            :campaign-id="campaign.id" @created="addSocialAction"/>
          <DataTable :table="actions.table" :dataset="actions.data" empty="social.no-results">
            <template #column_platform="{value}">
              {{ $translate(`social.${value}`) }}
            </template>
            <template #column_action="{value, row}">
              {{ $translate(`social.actions.${row.platform}.${value}`) }}
            </template>
            <template #column_points="{value}">
                            <span
                                class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gray-100 text-gray-800">
                                {{ value }}
                            </span>
            </template>
            <template #image>
              <img src="../../assets/images/social.svg" class="h-32 m-auto mt-16 mb-8"/>
            </template>
            <template #empty>
                            <span class="inline-flex rounded-md shadow-sm">
                                <button type="button" @click.prevent="actions.edit = {}; actions.visible = true"
                                        class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                    {{ $translate('social.create.button') }}
                                </button>
                            </span>
            </template>
          </DataTable>
        </Step>
        <Step :name="$translate('campaign.categories.step')" :valid="categories.valid"
              :loading="campaign.loading"
              v-if="details.values.reason !== 'brand-audience'">
          <CardHeader :name="$translate('campaign.categories.title')"
                      :description="$translate('campaign.categories.description')">
            <DataForm class="pt-6" :form="categories.form" v-model="categories.values"/>
          </CardHeader>
        </Step>
        <Step :name="$translate('campaign.purchase.step')"
              :valid="purchase.valid"
              :loading="campaign.loading"
              :load="fetchQuotation"
              next="campaign.purchase.finish">
          <CardHeader :name="$translate('campaign.purchase.title')"
                      :description="$translate('campaign.purchase.description')">
            <Alert class="mt-4" :title="$translate(`campaign.purchase.from.${quotation.wallet ? 'wallet' : 'stripe'}`, {price: totalPrice})" type="info" />
          </CardHeader>
          <dl class="divide-y divide-gray-200 -mt-4" v-if="quotation.currency !== ''">
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                 v-for="(line, index) in quotation.lines" :key="`line_${index}`">
              <dt class="text-sm font-medium text-gray-500 sm:col-span-2">{{
                  line.description
                }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 text-right">
                {{
                  new Intl.NumberFormat(locale, {
                    style: 'currency', currency:
                    quotation.currency
                  }).format(line.price)
                }}
              </dd>
            </div>
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 sm:col-span-2 font-bold">
                Total campaign cost
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 text-right font-bold">
                {{ totalPrice }}
              </dd>
            </div>
          </dl>
        </Step>
      </Steps>
    </div>
  </div>
  <CreateChannel v-model:visible="createChannel.show" :brand="products.values.brand" @channel="reloadChannels"/>
  <CreateBrand v-model:visible="createBrand.show" @brand="loadBrands"/>
</template>

<script lang="ts">
import {defineComponent, reactive, computed, watch, watchEffect} from 'vue';
import router from "@/router";
import {BaseForm} from "@/types/form";
import {
  CheckboxField,
  SelectField,
  TextField,
  NumberField,
  DateField,
  TextareaField,
  TagField
} from "@/types/field";
import {
  MaxDateValidationRule,
  MinDateValidationRule,
  MinLengthValidationRule,
  OperatorValidationRule,
  RequiredValidationRule,
  ValidationOperator
} from "@/types/validation";
import {FieldOption} from "@/types/option";
import CreateChannel from "@/pages/channels/CreateChannel.vue";
import CreateBrand from "@/pages/brands/CreateBrand.vue";
import {$axios, getErrorMessage, useAxios} from "@/plugins/axios";
import {Logic, LogicMatch, LogicMethod, LogicRule, LogicRuleOperator} from "@/types/logic";
import {useTranslation} from "@/plugins/i18n";
import {userStore} from "@/store/user";
import CompanyProfileForm from '@/pages/profile/CompanyForm.vue';
import SocialProfilesForm from "@/pages/profile/SocialProfilesForm.vue";
import {BaseAction, BaseTable, DeleteAction, Pagination, TextColumn} from "@/types/table";
import {useConfirm} from "@/plugins/confirm";
import SocialActionView from "@/pages/campaigns/SocialAction.vue";
import FaqLink from "@/pages/faq/FaqLink.vue";
import {useNotification} from "@/plugins/notification";
import {useDate} from '@/plugins/date';
import moment from "moment";
import {Product, CategoryOption, SocialAction} from "@/interfaces/campaign";
import {Quotation, QuotationLine} from "@/interfaces/quotation";
import {useStripe} from "@/plugins/stripe";
import {appStore, Brand} from "@/store/app";
import {Tooltip} from "@/types/tooltip";
import {useRouterHelper} from "@/plugins/routerHelper";

export default defineComponent({
  name: "CreateCampaign",
  components: {
    CreateChannel,
    CreateBrand,
    CompanyProfileForm,
    SocialProfilesForm,
    SocialActionView,
    FaqLink,
  },
  setup() {

    //  Campaign object
    const campaign = reactive({
      id: null,
      loading: false,
      hashtags: []
    });

    //  Axios plugin
    const axios = useAxios();

    //  Confirmation plugin
    const confirm = useConfirm();

    //  Translation plugin
    const i18n = useTranslation();

    //  Notification plugin
    const notification = useNotification();

    //  Router helper
    const routerHelper = useRouterHelper();

    //  Stripe plugin
    const stripe = useStripe();

    //  Quotation
    const quotation = reactive({
      currency: 'USD',
      total: 0,
      lines: [],
      wallet: false
    } as Quotation);

    const detailsValue = reactive({
      title: '',
      reason: 'social-engagement',
    });

    /**
     * Step: Categories
     */
    const categoriesForm = reactive(new BaseForm());
    const categories = reactive({
      form: categoriesForm,
      values: {
        categories: []
      },
      valid: () => {
        return new Promise((resolve, reject) => {
          categoriesForm.submit().then(() => {
            axios.put(`campaign/${campaign.id}/categories`, {
              category_keys: categories.values.categories
            }).then(() => {
              resolve();
            }).catch(error => {
              notification.error(i18n.translate('campaign.categories.error'), getErrorMessage(error.response.data));
              reject();
            });
          }).catch(() => {
            notification.error(i18n.translate('campaign.categories.error'), i18n.translate('campaign.categories.message'));
            reject();
          });
        });
      }
    });

    /**
     *  Step ?: Social actions
     */
    const actions = reactive({
      visible: false,
      edit: {},
      table: new BaseTable(Pagination.None),
      data: [],
      available: {},
      valid: () => {
        return new Promise((resolve, reject) => {
          if (actions.data.length === 0) {
            notification.error(i18n.translate('social.details.step'), i18n.translate('social.no-results'));
            reject();
          }
          resolve();
        });
      }
    });

    //  Add table columns
    actions.table.addColumn(new TextColumn('platform', 'social.platform'))
    actions.table.addColumn(new TextColumn('action', 'social.action').hideMobile())
    actions.table.addColumn(new TextColumn('points', 'social.points').hideMobile())

    //  Define table row actions
    actions.table.addAction(new BaseAction('table.action.edit').onClick((record: SocialAction) => {
          actions.visible = true;
          actions.edit = record;
        })
    );
    actions.table.addAction(new DeleteAction('table.action.edit').onClick((record: SocialAction, index: number) => {
          confirm.delete(i18n.translate('form.delete.title'), i18n.translate('form.delete.description')).then(() => {
            actions.table.loading = true;
            axios.delete(`campaign-action/${record.id}`).then(() => {
              loadCampaign().then(() => {
                actions.table.loading = false;
              }).catch(() => {
                actions.table.loading = false;
              })
            }).catch(() => {
              actions.table.loading = false;
            })
          });
        })
    );

    const addSocialAction = () => {
      actions.table.loading = true;
      loadCampaign().then(() => {
        actions.table.loading = false;
      }).catch(() => {
        actions.table.loading = false;
      });
    }

    /**
     * Schedule
     */
    const scheduleForm = new BaseForm();
    const scheduleProductField = new NumberField('products', 'campaign.schedule.products')
        .addRule(new RequiredValidationRule())
        .addRule(new MinLengthValidationRule(1));
    scheduleForm.addField(scheduleProductField);

    const scheduleStartDate = new DateField('start_date', 'campaign.schedule.start_date')
        .addRule(new RequiredValidationRule())
        .addRule(new MinDateValidationRule(moment().add(2, 'days').toDate()))
        .setWidth(6);
    scheduleForm.addField(scheduleStartDate);

    const scheduleEndDate = new DateField('end_date', 'campaign.schedule.end_date')
        .addRule(new RequiredValidationRule())
        // .addRule(new MinDateValidationRule(moment().add(2, 'days').add(1, 'month').toDate()))
        .setWidth(6);
    scheduleForm.addField(scheduleEndDate);

    const date = useDate();

    const scheduleValid = (): Promise<any> => {
      return new Promise((resolve, reject) => {
        scheduleForm.setError('').submit().then(() => {
          axios.put(`campaign/${campaign.id}/absolute`, {
            start_date: schedule.values.start_date,
            end_date: schedule.values.end_date,
            timezone: 'America/Los_Angeles',
            number_of_products: schedule.values.products,
          }).then(() => {
            resolve();
          }).catch(error => {
            schedule.form.setError(getErrorMessage(error.response.data));
            reject();
          })
        }).catch(() => {
          notification.error(
              i18n.translate('campaign.schedule.error'),
              i18n.translate('campaign.schedule.message')
          );
          reject();
        })
      });
    }
    const schedule = reactive({
      form: scheduleForm,
      values: {
        products: '',
        start_date: date.formatDate(moment().add(2, 'days').toDate(), 'YYYY-MM-DD'),
        end_date: date.formatDate(moment().add(2, 'days').add(1, 'month').toDate(), 'YYYY-MM-DD'),
      },
      valid: () => {
        return new Promise((resolve, reject) => {
          Promise.all([
            scheduleValid(),
          ]).then(() => resolve()).catch(() => reject());
        });
      }
    });

    watch(() => schedule, (schedule) => {
      //  Check if periode is at least on month between start and end date
      const months = moment(schedule.values.end_date).diff(moment(schedule.values.start_date), 'months')
      if (months <= 0) {
        schedule.values.end_date = date.formatDate(moment(schedule.values.start_date).add(1, 'month').toDate(), 'YYYY-MM-DD');
      }
      scheduleEndDate.removeRules()
          .addRule(new RequiredValidationRule())
          .addRule(new MinDateValidationRule(moment(schedule.values.start_date).add(1, 'month').toDate()));
    }, {deep: true})

    /**
     * Step 1: Campaign details
     */
    const detailsForm = reactive(new BaseForm());
    detailsForm.addField(
        new TextField('title', 'campaign.title')
            .addRule(new RequiredValidationRule())
            .addRule(new MinLengthValidationRule(6))
    );
    const campaignTypeField = new SelectField('reason', 'campaign.giveaway-goal').setTooltip(new Tooltip('Tooltip text'));
    appStore.getState().campaigns.forEach((campaign: String) => {
      campaignTypeField.addOption(new FieldOption(campaign, `campaign.reasons.${campaign}`))
    });
    detailsForm.addField(campaignTypeField);
    const validateDetails = (): Promise<any> => {
      return new Promise((resolve, reject) => {
        detailsForm.submit().then(() => {
          if (!campaign.id) {
            axios.post(`campaign`, {
              name: detailsValue.title,
              reason: detailsValue.reason
            }).then(response => response.data).then(response => {
              campaign.id = response.campaign.id;
              loadCampaignActions();
              resolve();
            }).catch(error => {
              notification.error(i18n.translate('campaign.details.error'), getErrorMessage(error.response.data));
              reject();
            });
          } else {
            axios.put(`campaign/${campaign.id}`, {
              name: detailsValue.title,
              reason: detailsValue.reason
            }).then(response => response.data).then(response => {
              resolve();
            }).catch(error => {
              notification.error(i18n.translate('campaign.details.error'), getErrorMessage(error.response.data));
              reject();
            });
            resolve();
          }
        }).catch(() => reject());
      });
    }
    const details = reactive({
      form: detailsForm,
      values: detailsValue,
      valid: () => {
        return new Promise((resolve, reject) => {
          Promise.all([
            validateDetails(),
          ]).then(() => resolve()).catch(() => reject());
        });
      },
    })

    //  Set min start date based on campaign type
    watchEffect(() => {
      scheduleStartDate.removeRules();
      if (details.values.reason === 'brand-audience') {
        scheduleStartDate
            .addRule(new RequiredValidationRule())
            .addRule(new MinDateValidationRule(moment().add(0, 'days').toDate()))
        schedule.values.start_date = date.formatDate(moment().add(0, 'days').toDate(), 'YYYY-MM-DD');
        schedule.values.end_date = date.formatDate(moment().add(0, 'days').add(1, 'months').toDate(), 'YYYY-MM-DD');
      } else {
        scheduleStartDate
            .addRule(new RequiredValidationRule())
            .addRule(new MinDateValidationRule(moment().add(2, 'days').toDate()))
        schedule.values.start_date = date.formatDate(moment().add(2, 'days').toDate(), 'YYYY-MM-DD');
        schedule.values.end_date = date.formatDate(moment().add(2, 'days').add(1, 'months').toDate(), 'YYYY-MM-DD');
      }
    })

    /**
     * Step 2: Company details (optional, only when not provided)
     */
    let onSaveCompany: Function = (): Promise<any> => new Promise((resolve, reject) => resolve());
    const bindSaveMethodCompany = (save: Function) => onSaveCompany = save;
    const saveCompany = () => {
      return new Promise((resolve, reject) => {
        onSaveCompany().then(() => resolve()).catch((error: any) => reject(error));
      });
    }


    let onSaveSocial: Function = (): Promise<any> => new Promise((resolve, reject) => resolve());
    const bindSaveMethodSocial = (save: Function) => onSaveSocial = save;
    const saveSocial = () => {
      return new Promise((resolve, reject) => {
        onSaveSocial().then(() => resolve()).catch((error: any) => reject(error));
      });
    }

    /**
     * Step 3: Campaign product
     */
    let currentProduct: Product = reactive({
      id: null,
      name: '',
      image: '',
      sku: '',
      price: 0,
      currency: '',
    });
    const productsValue = reactive({
      brand: '',
      brandName: '',
      channel: 'none',
      search: ''
    });
    let createBrand = reactive({show: false});
    let createChannel = reactive({show: false});
    const brandField = new SelectField('brand', 'campaign.brand.choose')
        .setCreate('campaign.brand.create', () => createBrand.show = true)
        .addRule(new RequiredValidationRule())
        .addRule(new OperatorValidationRule(ValidationOperator.NotEqual, ''));
    const loadBrands = () => {
      brandField.removeOptions();
      if (appStore.getState().brands.length === 0) {
        brandField.addOption(new FieldOption('', 'campaign.brand.empty').setDisabled())
        productsValue.brand = '';
      } else {
        appStore.getState().brands.forEach((brand: Brand, index: number) => {
          brandField.addOption(new FieldOption(brand.id, brand.name).preventTranslate())
          if (index === 0) {
            productsValue.brand = brand.id;
          }
        })
      }
    }
    loadBrands();

    const channelField = new SelectField('channel', 'campaign.connection.choose')
        .setCreate('campaign.connection.create', () => createChannel.show = true)
        .addOption(new FieldOption('none', 'campaign.connection.loading').setDisabled())
        .setLogic(new Logic()
            .setMatch(LogicMatch.Any)
            .setOperator(LogicMethod.Show)
            .addRule(new LogicRule('brand', LogicRuleOperator.IsNot, ''))
        )
        .addRule(new RequiredValidationRule())
        .addRule(new OperatorValidationRule(ValidationOperator.NotEqual, 'none'));
    const productsForm = reactive(new BaseForm());
    productsForm.addField(brandField);
    productsForm.addField(channelField);
    productsForm.addField(
        new TextField('search', 'campaign.products.search.caption', 'campaign.products.search.placeholder')
            .setLogic(new Logic()
                .setMatch(LogicMatch.All)
                .setOperator(LogicMethod.Show)
                .addRule(new LogicRule('channel', LogicRuleOperator.IsNot, 'none'))
                .addRule(new LogicRule('channel', LogicRuleOperator.IsNot, 'empty'))
            )
    );
    const products = reactive({
      form: productsForm,
      values: productsValue,
      valid: () => {
        return new Promise((resolve, reject) => {
          Promise.all([productsForm.submit(), isValidProductSelected()]).then(() => resolve()).catch(() => reject());
        });
      }
    });

    //  Retrieve your e-commerce channels
    const channels = [];
    const fetchChannels = (): Promise<any> => {
      return new Promise((resolve, reject) => {
        productsValue.channel = 'none';
        channelField.removeOptions().addOption(new FieldOption('none', 'campaign.connection.loading').setDisabled());
        $axios.get(`channels/my?brand_name_id=${productsValue.brand}`)
            .then(response => response.data)
            .then(response => {
              channelField.removeOptions();
              if (response.channels.length > 0) {
                response.channels.forEach((marketplace: any, index: number) => {
                  channels.push(marketplace);
                  channelField.addOption(new FieldOption(marketplace.id, marketplace.name).preventTranslate());
                  if (index === 0) {
                    productsValue.channel = marketplace.id;
                  }
                });
              } else {
                productsValue.channel = 'empty';
                channelField.addOption(new FieldOption('empty', 'campaign.connection.none').setDisabled());
              }
              resolve();
            })
            .catch(error => reject());
      });
    }

    //  Search product
    let productTimeout: null | number = null;
    const productSelection = reactive({
      id: null,
      sku: null,
      loading: false,
      list: [],
    });
    const clearSearch = () => {
      productSelection.id = null;
      productSelection.sku = null;
      productSelection.loading = false;
      productSelection.list = [];
      products.values.search = '';
    }

    //  Fetch channels on changed brand
    watch(() => productsValue.brand, (id: string) => {
      clearSearch();
      productsValue.brandName = '';
      if (id !== '') {
        const brand = appStore.getState().brands.filter((brand: Brand) => brand.id === id);
        productsValue.brandName = brand.length === 1 ? brand[0].name : '';
        fetchChannels();
      }
    }, {immediate: true})

    const reloadChannels = () => {
      fetchChannels().then(() => {
      }).catch(() => {
      })
    }
    const searchProduct = (term: string) => {
      productSelection.id = null;
      productSelection.sku = null;
      productSelection.loading = false;
      productSelection.list = [];
      if (term != '') {
        productSelection.loading = true;
        if (productTimeout) {
          clearTimeout(productTimeout);
        }
        productTimeout = setTimeout(() => {
          axios.get(`channel/${productsValue.channel}/products?current_page=1&query=${term}`)
              .then(response => response.data)
              .then(response => {
                const products: Product[] = [];
                response.products.forEach((product: any) => {
                  product.skus.forEach((sku: any) => {
                    const item: Product = {
                      id: product.mis_product_id,
                      name: product.name,
                      image: product.image,
                      sku: sku.sku,
                      price: sku.price,
                      currency: sku.currency,
                    }
                    products.push(item);
                  })
                });
                productSelection.list = (products as []);
                productSelection.loading = false;
              })
              .catch(error => {
                productSelection.list = [];
                productSelection.loading = false;
                notification.error(i18n.translate(`channels.no-products`), getErrorMessage(error.response.data));
              });
        }, 250);
      }
    }

    const isValidProductSelected = (): Promise<any> => {
      return new Promise((resolve, reject) => {
        //  Check if campaign has a product
        if (currentProduct.id) {
          resolve();
        } else if (productsValue.channel != 'none' && productSelection.id === null) {
          productsForm.setError(i18n.translate('campaign.products.error'));
          reject();
        } else {
          axios.put(`campaign/${campaign.id}/product`, {
            channel_id: productsValue.channel,
            mis_product_id: productSelection.id,
            product_sku: productSelection.sku,
          })
              .then(() => {
                loadCampaignActions();
                loadCampaign().then(() => resolve()).catch(() => reject());
              })
              .catch(error => {
                productsForm.setError(getErrorMessage(error.response.data));
                reject();
              });
        }
      })
    }

    const searchTerm = computed(() => productsValue.search);
    watch(() => searchTerm, (searchTerm) => {
      searchProduct(searchTerm.value);
    }, {deep: true, immediate: true});

    const onChangedStep = (step: number) => {
      if (step != 0) {
        detailsForm.setError('');
      }
      if (step != 1) {
        productsForm.setError('');
      }
    }

    const loadCampaign = (): Promise<any> => {
      return new Promise((resolve, reject) => {
        actions.data = [];
        axios.get(`campaign/${campaign.id}`).then(response => response.data).then(response => {
          //  Set details
          details.values.title = response.campaign.name;
          details.values.reason = response.campaign.reason;

          //  Set campaign actions
          actions.data = response.campaign.campaign_actions;

          //  Set categories
          categories.values.categories = response.campaign.categories.map((category: CategoryOption) => category.key);

          //  Set schedule
          schedule.values.products = response.campaign.number_of_products;

          //  Set product
          if (response.campaign.product) {
            currentProduct.id = response.campaign.product.id;
            currentProduct.name = response.campaign.product.name;
            currentProduct.sku = response.campaign.product.sku;
            currentProduct.price = response.campaign.product.price;
            currentProduct.currency = response.campaign.product.currency;
          }

          //  Pitch
          pitch.values.image = response.campaign.image_instruction;
          pitch.values.post = response.campaign.post_instruction;
          resolve();

        }).catch(() => reject());
      })
    }

    const pitch = reactive({
      form: new BaseForm()
          .addField(new TextareaField('image', 'campaign.pitch.image.caption', 'campaign.pitch.image.placeholder')
              .addRule(new RequiredValidationRule()))
          .addField(new TextareaField('post', 'campaign.pitch.post.caption', 'campaign.pitch.post.placeholder')
              .addRule(new RequiredValidationRule()))
          .addField(new TagField('hashtags', 'campaign.pitch.hashtags')
              .setCreatePlaceholder('Add a hashtag')
              .onChangedTags((tags: string[]) => {
                const parseTags: string[] = [];
                tags.forEach((tag: string, index: number) => {
                  const cleanTag = tag
                      .toString()
                      .toLowerCase()
                      .replace(/[^\w-\s]/gi, '')
                      .trim()
                      .replace(/\s+/g, '-');
                  if (cleanTag.length > 0 && parseTags.indexOf(`#${cleanTag}`) === -1) {
                    parseTags.push(`#${cleanTag}`);
                  }
                });
                //  @ts-ignore
                pitch.values.hashtags = parseTags;
              })
              .addRule(new RequiredValidationRule())
              .addRule(new MinLengthValidationRule(
                  3,
                  i18n.translate('campaign.pitch.hashtag').toLowerCase(),
                  i18n.translate('campaign.pitch.hashtags').toLowerCase()
              ))),
      values: {
        hashtags: [],
        image: '',
        post: ''
      },
      valid: () => {
        return new Promise((resolve, reject) => {
          pitch.form
              .setError('')
              .submit()
              .then(() => {
                Promise.all([
                  axios.put(`campaign/${campaign.id}/hashtags`, {
                    hashtags: pitch.values.hashtags
                  }),
                  axios.put(`campaign/${campaign.id}/instructions`, {
                    image_instruction: pitch.values.image,
                    post_instruction: pitch.values.post,
                  })
                ]).then(results => {
                  resolve();
                }).catch(error => {
                  reject(getErrorMessage(error.response.data))
                });
              })
              .catch(() => {
                reject();
              });
        });
      }
    })

    const loadCampaignHashtags = () => {
      return new Promise((resolve, reject) => {
        axios.get(`campaign/${campaign.id}/hashtags`).then(response => response.data).then(response => {
          campaign.hashtags = response.hashtags.map((item: { hashtag: string }) => item.hashtag);
          pitch.values.hashtags = response.hashtags.map((item: { hashtag: string }) => item.hashtag);
          resolve();
        }).catch(error => reject(error));
      });
    }

    const loadCampaignActions = () => {
      return new Promise((resolve, reject) => {
        axios.get(`available-campaign-actions/${campaign.id}`).then((response) => {
          //  Campaign actions
          actions.available = response.data.campaign_actions;
          resolve();
        }).catch(() => reject());
      });
    }

    const resources: Array<any> = [];
    resources.push(axios.get(`categories`));
    /**
     *  Check if campaign flow is an edit existing campaign
     */
    if (router.currentRoute.value.params.id) {
      // @ts-ignore
      campaign.id = router.currentRoute.value.params.id;

      //  Fetch current campaign
      resources.push(loadCampaign());

      //  Fetch current campaign hashtags
      resources.push(loadCampaignHashtags());

      //  Fetch campaign actions
      resources.push(loadCampaignActions());
    }
    else if (appStore.getState().campaigns.indexOf(routerHelper.getQueryParameter('type')) > -1) {
      detailsValue.reason = routerHelper.getQueryParameter('type');
    }

    //  Load necessary resources
    campaign.loading = true;
    Promise.all(resources).then(results => {
      campaign.loading = false;
      //  Categories
      const catagoriesField = new CheckboxField('categories', 'campaign.categories.title')
          .addRule(new MinLengthValidationRule(1))
          .setColumns(2);
      categoriesForm.removeFields();
      categoriesForm.addField(catagoriesField);
      results[0].data.categories.forEach((option: CategoryOption) => {
        catagoriesField.addOption(new FieldOption(option.key, option.caption).preventTranslate());
      });
    }).catch(error => {
    })

    const fetchQuotation = () => {
      campaign.loading = true;
      if (currentProduct.id && actions.data.length > 0 && schedule.values.products !== '') {
        axios.get(`campaign/${campaign.id}/quotation`)
            .then(response => response.data)
            .then(response => {
              campaign.loading = false;
              quotation.currency = response.quotation.currency
              quotation.total = response.quotation.total;
              quotation.lines = [];
              quotation.wallet = response.can_paid_from_wallet ?? false;
              response.quotation.lines.forEach((line: any) => {
                quotation.lines.push({
                  description: line.description,
                  price: line.amount as number
                } as QuotationLine);
              });
              quotation.lines.push({
                description: 'Vat',
                price: response.quotation.vat_amount as number
              } as QuotationLine);
            })
            .catch(error => {
              campaign.loading = false;
              notification.error(i18n.translate('campaign.name'), getErrorMessage(error.response.data));
            });
      }
    }

    /**
     *
     */
    const purchase = reactive({
      valid: () => {
        return new Promise((resolve, reject) => {
          axios
              .get(`campaign/${campaign.id}/checkout`)
              .then(response => {
                if (response.status === 202) {
                  //  Start stripe payment
                  stripe.payment(response.data.checkout.id);
                } else {
                  //  Paid from wallet
                  notification.success(
                      i18n.translate(`campaign.paid.title`),
                      i18n.translate(`campaign.paid.message`)
                  );
                  // @ts-ignore
                  router.push({name: 'detail-campaign', params: {id: campaign.id}, query: {created: details.values.reason}});
                  resolve();
                }
              })
              .catch(error => {
                notification.error(getErrorMessage(error.response.data));
                reject(error)
              })
        })
      }
    });

    return {
      locale: userStore.getState().locale,
      categories,
      campaign,
      details,
      companyComplete: {...{visible: userStore.isCompanyProfileComplete()}}.visible,
      socialComplete: {...{visible: userStore.isSocialProfileComplete()}}.visible,
      bindSaveMethodCompany,
      saveCompany,
      bindSaveMethodSocial,
      saveSocial,
      products,
      productSelection,
      router,
      createChannel,
      reloadChannels,
      createBrand,
      loadBrands,
      onChangedStep,
      actions,
      addSocialAction,
      schedule,
      currentProduct,
      quotation,
      purchase,
      fetchQuotation,
      pitch,
      totalPrice: computed(() => {
        return new Intl.NumberFormat(userStore.getState().locale, {
          style: 'currency', currency:
          quotation?.currency ?? 'USD'
        }).format(quotation?.total ?? 0)
      })
    }
  }
})
</script>
